/*
 * @Descripttion:
 * @version:
 * @Author: hutian
 * @Date: 2020-12-31 16:14:46
 * @LastEditors: hutian
 * @LastEditTime: 2021-02-03 10:00:15
 */
export const columns = [
    // {
    //     title: '序号',
    //     dataIndex: 'index',
    //     key: 'index',
    //     /*width:150,*/
    //     align:'center',
    //     ellipsis:true,
    //     scopedSlots: { customRender: 'index'}
    // },
    {
        title: '分类名称',
        dataIndex: 'name',
        key: 'name',
        width: 200,
        align:'left',
        ellipsis:true,
        fixed: 'left',
        scopedSlots: { customRender: 'name'}
    },
    {
      title: '分类编码',
      dataIndex: 'categoryCode',
      key: 'categoryCode',
      width: 200,
      align:'center',
      ellipsis:true,
      // scopedSlots: { customRender: 'categoryCode'}
    },
    {
      title: '三菱&非三菱',
      dataIndex: 'flagMitsubishi',
      key: 'flagMitsubishi',
      /*width:150,*/
      align:'center',
      scopedSlots: { customRender: 'flagMitsubishi'}
    },
    {
        title: '分类图标',
        dataIndex: 'imgUrl',
        key: 'imgUrl',
        /*width:150,*/
        align:'center',
        ellipsis:true,
        scopedSlots: { customRender: 'imgUrl'}
    },
    {
        title: '分类描述',
        dataIndex: 'remark',
        key: 'remark',
        /*width:150,*/
        align:'center',
        ellipsis:true
    },
    {
        title: '状态',
        dataIndex: 'flagEnable',
        key: 'flagEnable',
        /*width:150,*/
        align:'center',
        ellipsis:true,
        scopedSlots: { customRender: 'flagEnable'}
    },
    // {
    //     title: '排序',
    //     dataIndex: 'sort',
    //     key: 'sort',
    //     /*width:150,*/
    //     align:'center',
    //     ellipsis:true
    // },
    {
        title: '操作时间',
        dataIndex: 'modifyTime',
        key: 'modifyTime',
        /*width:150,*/
        align:'center',
        width: 200,
        ellipsis:true
    },
    // {
    //     title: '操作',
    //     dataIndex: 'action',
    //     /*width:150,*/
    //     align:'center',
    //     ellipsis:true,
    //     scopedSlots: {
    //         customRender: 'action'
    //     }
    // }
]
